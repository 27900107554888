import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import moment from "moment"
import RSSIcon from "../assets/rss.svg"
import { OutboundLink } from "gatsby-plugin-amplitude-analytics"
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}
const BlogItem = ({ data }) => {
  return (
    <div
      css={css`
        margin-bottom: 30px;
        padding: 15px 10px;

        border-bottom: 1px solid #ededed;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: baseline;
        `}
      >
        <h2
          css={css`
            font-size: 24px;
            margin: 0;
            font-weight: 400;
            margin-bottom: 2px;
            a {
              color: #050505;
              text-decoration: none;
            }
          `}
        >
          <OutboundLink target="_blank" href={data["URL"]}>
            {data["Name"]}
          </OutboundLink>
        </h2>
        <OutboundLink
          css={css`
            margin-left: 10px;
          `}
          target="_blank"
          href={data["RSS"]}
        >
          <RSSIcon
            css={css`
              width: 15px;
              height: 15px;
            `}
          />
        </OutboundLink>
      </div>
      <OutboundLink
        target="_blank"
        href={data["URL"]}
        css={css`
          font-size: 16px;
          color: #bababa;
        `}
        // TODO @dmitri make sure to style visited links too
      >
        ({data["URL"]})
      </OutboundLink>
      <div
        css={css`
          margin-top: 5px;

          a:hover {
            transition: none;
          }
        `}
      >
        {data["Tags"]?.map(tag => (
          <Link
            key={tag}
            css={css`
              font-size: 18px;
              color: #6b6b6b;
              margin-right: 7px;
              text-decoration: none;

              transition: color 1s linear;

              &:hover {
                color: ${`rgb(${getRandomInt(0, 200)},
                    ${getRandomInt(0, 200)},
                    ${getRandomInt(0, 200)})
                  })`};
              }
            `}
            to={`/tag/${tag.replace(".", "dot").replace(/ /g, "-")}`}
          >
            #{tag}
          </Link>
        ))}
      </div>
      <h3
        css={css`
          font-size: 18px;
          font-weight: normal;
          margin: 0;
          margin-top: 7px;
          color: #6b6b6b;
        `}
      >
        Latest post:{" "}
        <OutboundLink
          target="_blank"
          href={data["Last_Post_URL"]}
          css={css`
            color: #6b6b6b;
          `}
        >
          {data["Last_Post_Title"]}
        </OutboundLink>{" "}
        ({moment(data["Last_Post_Date"]).fromNow()})
      </h3>
    </div>
  )
}

export default BlogItem
