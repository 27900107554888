import React, { useState, useMemo } from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import BlogItem from "../components/BlogItem"
import Layout from "../components/Layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import axios from "axios"
import { tagNameToLabel } from "../utils/constants"

function getTitle(tag) {
  return `${tagNameToLabel(tag)} Blogs`
}

function getDescription(tag, count) {
  return `Best ${tagNameToLabel(tag)} blogs by individuals. ${count} blog${
    count > 1 ? "s" : ""
  } currently listed.`
}

export default function BlogPost({ data, pageContext: { tag } }) {
  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)
  const {
    allAirtable: { edges, totalCount },
  } = data

  const onRandomBlogClick = () => {
    const randomBlog = edges[Math.floor(Math.random() * edges.length)]
    window.open(randomBlog.node.data["URL"])
  }

  const renderBlogItems = () =>
    edges.map(edge => {
      const {
        node: { data },
      } = edge
      return <BlogItem key={data["URL"]} data={data} />
    })

  const onSubscribe = () => {
    if (email) {
      axios
        .post("https://slashblogs.com/subscribe", {
          email,
          tag,
        })
        .then(function (res) {
          setEmail("")
          console.log(res)
          setSubscribed(true)
        })
        .catch(function (err) {
          console.error(err)
        })
    }
  }

  return (
    <Layout>
      <SEO
        title={getTitle(tag)}
        description={getDescription(tag, totalCount)}
      />

      <div
        css={css`
          text-align: center;
          margin-top: 30px;
          margin-bottom: 50px;
        `}
      >
        <h1
          css={css`
            margin-bottom: 5px;
          `}
        >
          Blogs tagged "{tag}"
        </h1>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
          `}
        >
          ({totalCount} blogs)
        </span>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
            font-size: 18px;
            a {
              color: #0178bd;
            }
          `}
        >
          <Link to={`/tag/${tag.replace(".", "dot").replace(/ /g, "-")}/posts`}>
            View Posts
          </Link>
          {/* <button
            css={css`
              all: unset;
              color: #0178bd;
              text-decoration: underline;
              cursor: pointer;
            `}
            onClick={onRandomBlogClick}
          >
            Surprise Me!
          </button> */}
        </span>
        {/* <span>
          <h3>Get updated when we add new {tag} blogs </h3>
          <input
            css={css`
              padding: 5px 10px;
              font-size: 14px;
              border-radius: 5px;
              border: 1px solid #bababa;
            `}
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            placeholder="blogsurfer@gmail.com"
          ></input>
          <button
            css={css`
              background-color: #0178bd;
              color: #fff;
              border: none;
              border-radius: 5px;
              padding: 6px;
              margin-left: 3px;
              font-size: 12px;

              cursor: pointer;
            `}
            onClick={onSubscribe}
          >
            Subscribe
          </button>
        </span>
        {subscribed && (
          <div
            css={css`
              margin-top: 12px;
              font-size: 16px;
              color: green;
            `}
          >
            Successfully subscribed!
          </div>
        )} */}
      </div>

      <div
        css={css`
          max-width: 600px;
          margin: 0 auto;
        `}
      >
        {useMemo(() => renderBlogItems(), [edges])}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TagQuery($tag: String!) {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Timestamp: { ne: null }
          Tags: { eq: $tag }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { fields: data___Name, order: ASC }
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
